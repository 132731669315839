import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import '@/assets/import.js'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createHead } from "@vueuse/head"
const head = createHead()

createApp(App).use(store).use(Toast).use(head).use(router).mount('#app')
