<template>
  <li :class="['hover:bg-gray-500 p-2 cursor-pointer', $store.state.asideTab === props.tab ? 'bg-gray-500 text-gray-300' : '']" @click="changeTab(props.tab)">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-1">
        <span class="text-gray-300" :style="{color:randomColor()}"><i :class="props.icon"></i></span>
        <span class="text-gray-300">{{props.menu}}</span>
      </div>
      <span v-if="$store.state.asideTab !== props.tab"><i class="far text-sm fa-chevron-left text-gray-300"></i></span>
      <span v-else><i class="far text-sm fa-chevron-down text-gray-300"></i></span>
    </div>
  </li>
  <li v-if="$store.state.asideTab === props.tab">
    <ul class="flex flex-col">
      <li v-for="submenu in props.submenus" :key="submenu.id">
        <div class="hover:bg-gray-600 p-2 pr-6 cursor-pointer" v-if="submenu.perm === undefined || store.getters.userCan(submenu.perm,'include')">
          <routerLink :to="submenu.route" class="text-gray-300">{{submenu.title}}</routerLink>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
export default{name:"DashboardAside"}
</script>

<script setup>
import {ref, defineProps} from "vue";
import store from "@/store";

const props = defineProps({
  "tab":{
    type:Number,
    default:0
  },
  "icon":{
    type:String,
    default:""
  },
  "menu":{
    type:String,
    default:""
  },
  "submenus":{
    type:Array
  }
});
function changeTab(_tab){
  store.state.asideTab = _tab === store.state.asideTab ? 0 : _tab;
}
function randomColor(){
  let color = ['red','blue','green','yellow','cyan','orange','orangered','crimson','pink','magenta']
  return color[(Math.floor(Math.random() * color.length))];
}
</script>