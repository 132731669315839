import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import DashboardLayout from '@/components/DashboardLayout';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue')
  },
  {
    path: '/error/:status/:msg',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue')
  },
  {
    path: '/dashboard',
    meta: {requiresAuth: true},
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Panel/Dashboard.vue')
      },

      {
        path: '/network-power',
        name: 'NetworkPower',
        component: () => import(/* webpackChunkName: "users" */ '@/views/Panel/NetworkPower.vue')
      },
    ]

  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 };
  }
})

async function checkExpireCookie(){
  let expireAt = parseInt(store.getters.getCookie('expire_token'));
  let now = (new Date()).getTime();
  expireAt = isNaN(expireAt) ? now : expireAt;
  if(expireAt > now ){
    await store.dispatch('setCookie', 'expire_token')
  }else{
    store.commit('clearStorage')
  }
  // expireAt > now ? await store.dispatch('setCookie', 'expire_token') : store.commit('clearStorage')
}

router.beforeEach(async (to,from,next)=>{
  await checkExpireCookie();
  if(to.matched[0].path === '/dashboard'){
    store.state.toggleAside = false;
  }else{
    store.state.toggleNav = false;
  }
  if(to.matched.length <= 0){
    next({name:'Error', params:{status:'404', msg:'صفحه مورد نظر پیدا نشد'}})
  }
  if(!to.matched.length && to.fullPath !== '/'){
    next({name:'Error',params:{status:404,msg:'صفحه مورد نظر یافت نشد'}});
  }

  if(to.meta.requiresAuth === true){
    await store.dispatch('checkToken')
  }
  if(to.meta.requiresLogin && store.getters.getToken !== null){
    next({name:'Index'});
  }else if(to.meta.requiresAuth && !store.getters.getToken){
    next({name:'Index'});
  }else{
    next();
  }

});

export default router
