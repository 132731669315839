<template>
  <div>
    <div class="container-fluid">
      <div class="flex">
        <aside :class="['w-[23%] border-l border-[#15273b] min-h-screen bg-[#15273b] ', $store.state.toggleAside ? 'fm:block fm:fixed fm:w-[90%] fm:z-100' : 'fm:hidden']">
          <div class="flex flex-col gap-8 p-4">
            <div class="flex justify-between">
              <div class="flex items-center gap-2">
                <routerLink to="/dashboard">
                  <img src="@/assets/images/avatar.png" class="rounded-full w-[100px] h-[100px] fm:w-[60px] fm:h-[60px]"/>
                </routerLink>
                <div class="flex flex-col gap-2">
                  <span class="text-gray-300 !font-medium fm:text-sm">{{$store.state.user.name}}</span>
                </div>
              </div>
              <div class="fd:hidden">
                <span class="cursor-pointer" @click="$store.state.toggleAside = false"><i class="far fa-times text-lg"></i></span>
              </div>
            </div>
          </div>
          <div class="border-b border-[#15273b] w-full py-3"></div>
          <div class="">
            <ul>
              <li :class="['text-gray-300 p-2']">
                <div class="flex items-center justify-between">
                  <routerLink to="/" class="flex items-center gap-1">
                    <span class="text-gray-300"><i class="far fa-home-alt"></i></span>
                    <span class="text-gray-300">خانه</span>
                  </routerLink>
                </div>
              </li>
            </ul>
            <ul class="flex flex-col">
              <DashboardAside
                  icon="far fa-cubes"
                  :tab="1"
                  menu="network power"
                  :submenus="[
                      {id:1, title:'set network power', route:{name:'NetworkPower'}},
                  ]"
              />

            </ul>

          </div>
        </aside>
        <div :class="['flex flex-col fm:w-full fd:w-[77%]']">
          <header class="border-b border-gray-300 px-4 py-2 bg-white">
            <div class="flex items-center justify-between fd:justify-end">
              <div class="fd:hidden">
                <span class="cursor-pointer" @click="$store.state.toggleAside = !$store.state.toggleAside"><i class="far fa-bars text-xl fm:text-sm"></i></span>
              </div>
              <div class="flex items-center gap-10">
                <div class=" cursor-pointer group relative">
                  <div>
                    <img src="@/assets/images/avatar.png" class="rounded-full w-[40px] h-[40px]"/>
                  </div>
                  <div class="group-hover:block hidden bg-white shadow absolute top-[3rem] w-[12rem] left-0 rounded">
                    <ul>
                      <li class="bg-gray-100 p-2 hover:bg-gray-200 text-gray-600 fm:text-sm" @click="logout()">خروج</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <main class="p-4">
            <router-view></router-view>
          </main>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {name: "DashboardLayout"}
</script>

<script setup>
import {ref ,onMounted} from "vue";
import axios from "@/plugins/axios";
import DashboardAside from "@/components/DashboardAside";
import store from "@/store";
import { useRouter } from "vue-router";

const router = useRouter();
onMounted(async ()=>{
})

async function logout(){
  await axios.post(`${store.state.api}/logout`).then(resp=>{
    store.commit('clearStorage')
    router.push({name:'Index'})
  })
}

</script>

<style scoped>
*{
  direction: rtl;
}
</style>