import { createStore } from 'vuex'
import Toast from "@/plugins/toast";
import axios from "@/plugins/axios";
import {useFavicon} from "@vueuse/core";

export default createStore({
  state: {
    siteName:'Home',
    user:{
      'name':localStorage.getItem('name'),
      'id':localStorage.getItem('id'),
      'token':localStorage.getItem('token'),
    },
    errors:[],
    toggleAside:false,
    asideTab:0,
    api:'http://api.your-power.ir/api',
  },
  mutations: {
    handleError(state,payload){
      const response = payload.response;
      if(response === undefined || response.status === 500){
        let msg = response !== undefined ? response.data.msg : null;
        if(msg){
          Toast.error(msg)
        }else{
          Toast.error('یک خطای غیر منتظره رخ داده است')
        }
      }else if(response.status === 422 || response.status === 413){
        state.errors = Object.values(response.data.errors)
        state.validationErrorModal = true;
      }else if(response.status === 403 || response.status === 401){
        window.location.href = `/error/${response.status}/دسترسی شما غیر مجاز است برای بررسی بیشتر با ما تماس بگیرید`;
      }else if(response.status === 404){
        window.location.href = `/error/${response.status}/ صفحه مورد پیدا نشد`;
      }
    },
    async setStorage(state, payload){
      const setToken = payload[0]
      const userData = payload[1]
      localStorage.setItem('name',userData.name);
      localStorage.setItem('id',userData.id);
      state.user.name = userData.name;
      state.user.id = userData.id;
      if(setToken){
        localStorage.setItem('token',userData.token)
        state.user.token = userData.token;
      }

      await this.dispatch('setCookie', 'expire_token');

    },
    clearStorage(state){
      document.cookie = 'expire_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      state.user.name = null;
      state.user.id = null;
      state.user.token = null;
      localStorage.clear()
    }
  },
  actions: {
    setFavicon({state}, favicon){
      const icon = useFavicon()
      icon.value = favicon
    },
    async setCookie({state},name) {
      let expires = "";
      let date = new Date();
      let timestamp = date.setTime(date.getTime() + (24*60*60*100));
      expires = "; expires=" + date.toUTCString();
      document.cookie = name + "=" + timestamp  + expires + "; path=/";
    },
    async checkToken({state,commit}){
      await axios.get(`${state.api}/check-token`).then(async resp=>{
        const data = resp.data.data;
        if(data.token_valid){
          if(data.status === 'no'){
            commit('clearStorage')
            window.location.href = '/error/403/دسترسی شما غیر فعال میباشد برای بررسی بیشتر با ما تماس بگیرید'
          }else{
            state.userPermissions = data.permissions;
            await commit('setStorage',[false,data])
          }
        }else{
          commit('clearStorage')
        }
      }).catch(err=>{
        console.log(err)
        commit('clearStorage')
      })
    },
  },
  getters: {
    getToken(state){
      return state.user.token;
    },
    getErrors(state){
      return state.errors;
    },
    getCookie: (state) => (name) => {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length,c.length)
        }
      }
      return null;
    },
    copyToClipboard: (state) => async (text) => {
      if (navigator.clipboard && window.isSecureContext) {
        Toast.success('کپی شد')
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
          document.execCommand('copy');
          Toast.success('کپی شد')
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
    },
  },
})
